.wrapper {
  --header-height: 5.4rem;

  @apply py-4 relative bg-floor-white border-b border-floor-black border-opacity-10;

  height: var(--header-height);
}

.dropdown {
  --tw-backdrop-filter: blur(0.3rem);

  @apply fixed w-full backdrop-filter bg-opacity-95 bg-floor-white;

  top: var(--header-height);
  height: calc(100% - var(--header-height));
  transition: transform 500ms;
}
