.wrapper {
  @apply w-full;

  max-width: var(--size, 14rem);
}

.image-container {
  @apply relative block h-auto w-full overflow-hidden;

  padding: 100% 0 0 0;
}

.icon {
  @apply w-full h-full;

  color: #d1d5db;
}
